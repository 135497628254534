<template>
    <div class="page-content w-100">
      <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Profile and settings</p>
      </div>
    </div>
        <div class="mobie-setting-mainDiv">
            <a href="/user/info">
                <div class="SettingName">My info</div>
            </a>
            <a href="/user/family">
                <div class="SettingName">My family</div>
            </a>
            <a href="/user/topic-interest">
                <div class="SettingName">Topics of interest</div>
            </a>
            <a href="/user/community">
                <div class="SettingName">Communities and Groups</div>
            </a>
            <!-- <a href="/user/message">
                <div class="SettingName">Message settings</div>
            </a> -->
        </div>
    </div>
    <userfooter></userfooter>

</template>
<script>
import userfooter from "./user-footer.vue"
export default {
    components: {
    userfooter
    },
    props: ["role"],
    data() {
    return {

    }},
    created: function () {
  },
    methods: {
  currentPath(routePath) {
      this.$router.push(routePath);
    },
    isRouteActive(routePath) {
      return this.$route.path === routePath;
    },

    }
}
</script>
<style>
.SettingName {
    font-size: 19px;
    color: #4872e9;
    padding: 0.25rem 1rem;
    cursor: pointer;
}

.mobie-setting-mainDiv {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
</style>